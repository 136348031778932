<script>
export default {
  methods: {
    logout() {
      window.location.replace("/logout");
    },
  },
};
</script>

<template>
  <div class="maintenance">
    <img class="maintenance__icon" src="@/assets/images/under-maintainance.svg" />
    <div class="maintenance__info">
      <h1 class="maintenance__info-text" style="margin-bottom: 1rem">You don't have access to this page</h1>
      <p class="maintenance__info-text">
        We are sorry for the inconvenience, but the page you are trying to access is not available to you. <br />
        If you think this is a mistake, please contact our support team.
      </p>
      <dg-button @click="logout">Click here to log out.</dg-button>
    </div>
  </div>
</template>

<style lang="css" scoped>
.maintenance {
  gap: 2rem;
  margin: 0 auto;
  margin-top: 60px;
  max-width: 45%;
  display: flex;
  align-items: center;
  background-color: white;
  padding: 24px;
  box-shadow: 0 10px 16px -4px rgba(0, 0, 0, 0.5);

  &__icon {
    width: 75px;
  }

  &__info {
    margin-left: 24px;

    &-text {
      margin: 0;
      padding: 0;
    }

    *:not(:first-child) {
      margin-top: 12px;
    }
  }
}
</style>
